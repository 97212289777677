import $ from 'jquery';
import './navigation.scss';
import { navigationTooltips } from '../tooltip/tooltip';
import { getScrollBarWidth } from '../../utils/dom';

const ACTIVE_CLASS_NAME = 'dpd-navigation-h-icon--active';
const ACTIVE_CLASS_NAME_CONTENT = 'dpd-navigation-h-content--active';
const ACTIVE_CLASS_NAME_CONTENT_ANI = 'dpd-navigation-h-content--animated';

const hamburgerButton = $('#navigation-menu');
const hamburgerContent = $('#navigation-menu-content');

hamburgerButton.on('click', () => {
  if (!hamburgerContent.hasClass(ACTIVE_CLASS_NAME_CONTENT_ANI)) {
    hamburgerContent.addClass(ACTIVE_CLASS_NAME_CONTENT_ANI);
  }

  if (hamburgerButton.hasClass(ACTIVE_CLASS_NAME)) {
    hamburgerButton.removeClass(ACTIVE_CLASS_NAME);
    hamburgerContent.removeClass(ACTIVE_CLASS_NAME_CONTENT);
  } else {
    hamburgerButton.addClass(ACTIVE_CLASS_NAME);
    hamburgerContent.addClass(ACTIVE_CLASS_NAME_CONTENT);
  }
});

$('body').on('click touchstart', event => {
  if (
    hamburgerButton.hasClass(ACTIVE_CLASS_NAME) &&
    !$.contains(hamburgerContent.get(0), event.target) &&
    !(
      $.contains(hamburgerButton.get(0), event.target) ||
      hamburgerButton.is(event.target)
    )
  ) {
    hamburgerButton.removeClass(ACTIVE_CLASS_NAME);
    hamburgerContent.removeClass(ACTIVE_CLASS_NAME_CONTENT);
    navigationTooltips.tooltip('hide');
  }
});

$('.modal').on('show.bs.modal', () => {
  const scrollbarWidth = getScrollBarWidth();

  $('.dpd-navigation').css('right', `${scrollbarWidth}px`);
});

$('.modal').on('hidden.bs.modal', () => {
  $('.dpd-navigation').css('right', `${0}px`);
});
