import smoothscroll from 'smoothscroll-polyfill';
import $ from 'jquery';

smoothscroll.polyfill();

$('a[href][data-smooth]').on('click', event => {
  const href = event.currentTarget.href.replace(/.*\//, '');

  if (/^#.+/.test(href)) {
    event.preventDefault();

    const target = document.querySelector(href);

    if (target) {
      target.scrollIntoView({
        alignToTop: true,
        behavior: 'smooth',
      });
    }
  }
});
