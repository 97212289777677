import $ from 'jquery';
import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import './carousel.scss';
import { playAllVisibleVideos } from '../../utils/dom';
import { checkAndInitLazyContent } from '../content/content';

const carousels = document.querySelectorAll('.dpd-carousel');

const getNodeIndex = node => node.dataset?.index;

/**
 *
 * @param {Array<Element>} contentItems
 * @param {Array<Element>} bulletItems
 * @param {String} index
 * @returns {Element}
 */
const activateItem = (contentItems, bulletItems, index) => {
  forEach(contentItems, contentItem => {
    if (getNodeIndex(contentItem) === index) {
      contentItem.classList.add('dpd-carousel__content-item--active');
    } else {
      contentItem.classList.remove('dpd-carousel__content-item--active');
    }
  });

  forEach(bulletItems, bulletItem => {
    if (getNodeIndex(bulletItem) === index) {
      bulletItem.classList.add('dpd-carousel__bullet--active');
    } else {
      bulletItem.classList.remove('dpd-carousel__bullet--active');
    }
  });

  return bulletItems.find(bullet => getNodeIndex(bullet) === index);
};

/**
 *
 * @param {Element} carousel
 */
const initCarousel = carousel => {
  const contentItems = [
    ...carousel.querySelectorAll('.dpd-carousel__content-item'),
  ];
  const bulletItems = [...carousel.querySelectorAll('.dpd-carousel__bullet')];
  const content = carousel.querySelector('.dpd-carousel__content');

  const activeIndex = getNodeIndex(carousel);
  let activeBullet = activateItem(contentItems, bulletItems, activeIndex);

  forEach(bulletItems, bullet => {
    bullet.addEventListener('click', () => {
      activeBullet = activateItem(
        contentItems,
        bulletItems,
        getNodeIndex(bullet)
      );
      playAllVisibleVideos(carousel);
      checkAndInitLazyContent();
    });
  });

  const leftToRightSwipe = () => {
    if (
      activeBullet.previousElementSibling &&
      getNodeIndex(activeBullet.previousElementSibling)
    ) {
      activeBullet = activateItem(
        contentItems,
        bulletItems,
        getNodeIndex(activeBullet.previousElementSibling)
      );
      playAllVisibleVideos(carousel);
      checkAndInitLazyContent();
    }
  };

  const rightToLeftSwipe = () => {
    if (
      activeBullet.nextElementSibling &&
      getNodeIndex(activeBullet.nextElementSibling)
    ) {
      activeBullet = activateItem(
        contentItems,
        bulletItems,
        getNodeIndex(activeBullet.nextElementSibling)
      );
      playAllVisibleVideos(carousel);
      checkAndInitLazyContent();
    }
  };

  // touch support
  let startX = null;
  content.addEventListener(
    'touchstart',
    function(event) {
      if (event.touches.length === 1) {
        // just one finger touched
        startX = event.changedTouches[0].pageX;
      } else {
        // a second finger hit the screen, abort the touch
        startX = null;
      }
    },
    false
  );
  content.addEventListener(
    'touchend',
    function(event) {
      const offset = 120; // at least 120px are a swipe
      if (startX) {
        // the only finger that hit the screen left it
        const end = event.changedTouches[0].pageX;

        if (end - startX >= offset) {
          // a left -> right swipe
          leftToRightSwipe();
          return;
        }
        if (startX - end >= offset) {
          // a right -> left swipe
          rightToLeftSwipe();
        }
      }
    },
    false
  );

  return {
    name: carousel.dataset?.name,
    next: rightToLeftSwipe,
    previous: leftToRightSwipe,
    activate: index => {
      activeBullet = activateItem(contentItems, bulletItems, index);
      playAllVisibleVideos(carousel);
      checkAndInitLazyContent();
    },
  };
};

const carouselHash = keyBy(
  [...carousels].map(initCarousel),
  ({ name }) => name
);

const activateCarousel = (name, index, location) => {
  // eslint-disable-next-line no-unused-expressions
  carouselHash[name]?.activate?.(index);

  if (location) {
    const href = location.replace(/.*\//, '');
    const target = document.querySelector(href);

    if (target) {
      target.scrollIntoView({
        alignToTop: true,
        behavior: 'smooth',
      });
    }
  }
};

$('[data-c-button]').on('click', event => {
  const target = event.currentTarget;

  const name = target.dataset.name;
  const index = target.dataset.index;
  const location = target.dataset.location;

  activateCarousel(name, index, location);
});
