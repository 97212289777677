import $ from 'jquery';
import throttle from 'lodash/throttle';

import { isVisibleInViewport, playAllVisibleVideos } from '../../utils/dom';
import {
  checkAndInitLazyContent,
  checkAndInitLazyImages,
} from '../content/content';
import './scenes.scss';
import './animation.scss';
import { withPreviousResult } from '../../utils/functions';

const VIEWED_CLASS_NAME = 'dpd-scene--viewed';

const scenes = [...document.querySelectorAll('.dpd-scene')];

const updateScenes = () => {
  checkAndInitLazyContent();

  scenes.forEach((scene, index) => {
    if (
      isVisibleInViewport(scene) &&
      !scene.classList.contains(VIEWED_CLASS_NAME)
    ) {
      checkAndInitLazyImages(scene);
      // load images on one screen forward
      if (scenes[index + 1]) {
        checkAndInitLazyImages(scenes[index + 1]);
      }
    }

    if (
      isVisibleInViewport(scene, 0.25) &&
      !scene.classList.contains(VIEWED_CLASS_NAME)
    ) {
      scene.classList.add(VIEWED_CLASS_NAME);
      playAllVisibleVideos(scene);

      // fix safari fullscreen mode doesn't work if animation-fill-mode=forward
      setTimeout(() => {
        $(scene)
          .find('.dpd-scale-up-iframe')
          .addClass('animated');
      }, 400);
    }
  });
};

const HEIGHT_SENSITIVE = 120;
const resizeHandler = withPreviousResult(
  ({ height }) => {
    const windowHeight = $(window).height();

    if (Math.abs(height - windowHeight) > HEIGHT_SENSITIVE) {
      $('.dpd-scene').css('min-height', `${windowHeight}px`);

      return {
        height: windowHeight,
      };
    }

    return {
      height,
    };
  },
  { height: 0 }
);

// fix content jumping on mobile devices (firefox[ios, android])
$(document).ready(resizeHandler);
$(window).resize(throttle(resizeHandler, 400));

document.addEventListener('load', updateScenes);
// ios support to play videos
document.addEventListener('touchend', updateScenes);
document.addEventListener('gesturechange', throttle(updateScenes, 300));
document.addEventListener('scroll', throttle(updateScenes, 300));
