import VimeoPlayer from '@vimeo/player';
import $ from 'jquery';

import './content.scss';
import { isElementHidden, isVisibleInViewport } from '../../utils/dom';

$('video.dpd-video').each((_, video) => {
  video.addEventListener('click', () => video.play());
});

const lazyIframes = $('iframe[data-lazy-src]');

const stopIfVimeoVideo = iframe => {
  if (!/player.vimeo/.test(iframe.src)) {
    return;
  }

  if (!iframe.vimeo) {
    iframe.vimeo = new VimeoPlayer(iframe);
  }

  iframe.vimeo.pause();
};

const checkAndInitLazyIframes = () => {
  lazyIframes.each((index, iframe) => {
    if (isVisibleInViewport(iframe) && !isElementHidden(iframe)) {
      if (iframe.src !== iframe.dataset.lazySrc) {
        iframe.src = iframe.dataset.lazySrc;
      }
    } else {
      stopIfVimeoVideo(iframe);
    }
  });
};

export const checkAndInitLazyImages = content => {
  $(content)
    .find('img[data-lazy-src]')
    .each((index, img) => {
      if (img.src !== img.dataset.lazySrc) {
        img.src = img.dataset.lazySrc;
      }
    });
};

export const checkAndInitLazyContent = () => {
  checkAndInitLazyIframes();
};

$('.modal').on('shown.bs.tab', checkAndInitLazyContent);
$('.modal').on('hidden.bs.tab', checkAndInitLazyContent);

$('.modal').on('shown.bs.modal', checkAndInitLazyContent);
$('.modal').on('hidden.bs.modal', checkAndInitLazyContent);
