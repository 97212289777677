import './index.scss';

import './components/smoothscroll/smoothscroll';
import './components/scenes/scenes';
import './components/content/content';
import './components/carousel/carousel';
import './components/header/header';
import './components/navigation/navigation';
import './components/forms/forms';
import './components/button/button';

import './scenes/scene-1-title';
import './scenes/scene-2-navigation';
import './scenes/scene-6-our-community';
import './scenes/scene-7-the-dpd-way';

import './analytics';

process.env.REACT_APP_ENABLE_REDIRECT &&
  window.location.assign(process.env.REACT_APP_ENABLE_REDIRECT);
