import $ from 'jquery';
import 'bootstrap/js/src/modal';

import './form-modal.scss';
import { validators, processForm } from './form-schema';

window.verifyRecaptchaCallback = function(response) {
  $('input[data-recaptcha]')
    .val(response)
    .trigger('change');
};

window.expiredRecaptchaCallback = function() {
  $('input[data-recaptcha]')
    .val('')
    .trigger('change');
};

$('.needs-validation').on('submit', event => {
  const form = event.currentTarget;

  if (!form.checkValidity()) {
    event.preventDefault();
  }

  form.classList.add('was-validated');
});

const showServerError = (form, error) => {
  if (error?.responseJSON) {
    error = error.responseJSON;
  }

  const errorElement = $(form).find('.server-error');

  errorElement.find('.fake-input').addClass('is-invalid');

  if (error.message) {
    errorElement.find('.invalid-feedback').text(error.message);
  } else {
    errorElement
      .find('.invalid-feedback')
      .text('Oops something went wrong. Please try again later');
  }
};

const showSuccessMessage = modal => {
  $(modal)
    .find('.modal-body')
    .addClass('submitted');
};

const startLoading = form => {
  const button = $(form).find('button[type="submit"]');
  const fieldset = $(form).find('fieldset');

  button.html(`Submitting...  <i class="fa fa-spinner fa-spin"></i>`);
  fieldset.prop('disabled', true);
};

const stopLoading = form => {
  const button = $(form).find('button[type="submit"]');
  const fieldset = $(form).find('fieldset');

  button.html(`Submit`);
  fieldset.prop('disabled', false);
};

const CONTACT_US_SCHEMA = {
  enquiry: validators.required,
  name: validators.required,
  message: validators.required,
  email: validators.email,
  contactNumber: validators.pipe(validators.required, validators.contactNumber),
  gdrp: validators.required,
  recaptchaToken: validators.required,
};

$('#contact-us form .form-control, #contact-us form .check-input').change(
  () => {
    const form = $('#contact-us form').get(0);
    const formData = new FormData(form);

    processForm(form, formData, CONTACT_US_SCHEMA);
  }
);

$('#contact-us form').on('submit', async event => {
  event.preventDefault();
  const form = event.currentTarget;
  const formData = new FormData(event.currentTarget);

  if (processForm(form, formData, CONTACT_US_SCHEMA, true)) return;

  startLoading(form);

  try {
    await $.ajax({
      url: `${process.env.REACT_APP_FORMS_API}/contact-us`,
      data: formData,
      method: 'POST',
      enctype: 'multipart/form-data',
      processData: false,
      contentType: false,
      timeout: 600 * 1000,
    });

    showSuccessMessage('#contact-us');
  } catch (error) {
    showServerError(form, error);
  }

  stopLoading(form);
});
