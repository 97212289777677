import $ from 'jquery';
import reduce from 'lodash/reduce';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import forEach from 'lodash/forEach';

/**
 *
 * @param {FormData} formData
 * @param {Object} schema
 */
export const validateSchema = (formData, schema) => {
  return reduce(
    schema,
    (errors, validator, key) => {
      errors[key] = validator(formData.get(key));

      return errors;
    },
    {}
  );
};

export const updateFormErrors = (formElement, errors) => {
  const form = $(formElement);
  forEach(errors, (error, key) => {
    const element = form.find(`[name="${key}"]`);

    if (error) {
      element.addClass('is-invalid');
      element.removeClass('is-valid');
    } else {
      element.removeClass('is-invalid');
      element.addClass('is-valid');
    }
  });
};

export const isErrorExist = errors => some(errors, identity);

// eslint-disable-next-line no-control-regex
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const CONTACT_NUMBER_REGEX = /^\d+$/;

export const validators = {
  required: value => (isEmpty(value) ? true : null),
  email: value => (!EMAIL_REGEX.test(value) ? true : null),
  contactNumber: value => (!CONTACT_NUMBER_REGEX.test(value) ? true : null),
  pipe: (...functions) => value => {
    for (const func of functions) {
      const result = func(value);
      if (!isNil(result)) {
        return result;
      }
    }
  },
};

export const processForm = (form, formData, schema, force) => {
  const errors = validateSchema(formData, schema);

  if ($(form).hasClass('was-validated') || force) {
    updateFormErrors(form, errors);
  }

  return isErrorExist(errors);
};
