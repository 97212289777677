import negate from 'lodash/negate';
import once from 'lodash/once';

export const isElementHidden = element => {
  return element.offsetParent === null;
};

export const isVisibleInViewport = (el, viewHeightPercentage) => {
  const scroll = window.pageYOffset || window.scrollY;
  const boundsTop = el.getBoundingClientRect().top + scroll;

  const viewport = {
    top: scroll,
    bottom: scroll + window.innerHeight,
  };

  if (typeof viewHeightPercentage === 'number') {
    viewport.bottom -= viewHeightPercentage * window.innerHeight;
  }

  const bounds = {
    top: boundsTop,
    bottom: boundsTop + el.clientHeight,
  };

  return (
    (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
    (bounds.top <= viewport.bottom && bounds.top >= viewport.top) ||
    (bounds.top <= viewport.top && bounds.bottom >= viewport.bottom)
  );
};

/**
 *
 * @param {Element} parentNode
 */
export const playAllVisibleVideos = parentNode => {
  const videos = [...parentNode.querySelectorAll('video.dpd-video')];
  // console.log(videos)

  videos.filter(negate(isElementHidden)).forEach(video => video.play());
};

export const getScrollBarWidth = once(() => {
  const scrollDiv = document.createElement('div');
  scrollDiv.className = 'modal-scrollbar-measure';
  document.body.appendChild(scrollDiv);

  const scrollbarWidth =
    scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
});
