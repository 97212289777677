import $ from 'jquery';
import 'bootstrap/js/src/tooltip';
import './tooltip.scss';

export const navigationTooltips = $(
  '.dpd-navigation [data-toggle="tooltip"]'
).tooltip({
  container: '.dpd-navigation',
  boundary: 'viewport',
});
